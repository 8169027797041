export default {
  data() {
    return {
      modulosContratacaoInquilino: [
        {
          text: this.$t("GESTAOPONTO"),
          value: "GESTAOPONTO",
        },
        {
          text: this.$t("GESTAOPESSOAS"),
          value: "GESTAOPESSOAS",
        },
        {
          text: this.$t("FATURAMENTO"),
          value: "FATURAMENTO",
        },
        {
          text: this.$t("FINANCEIRO"),
          value: "FINANCEIRO",
        },
        {
          text: this.$t("COMPRAS"),
          value: "COMPRAS",
        },
        {
          text: this.$t("ALMOXARIFADO"),
          value: "ALMOXARIFADO",
        },
        {
          text: this.$t("GED"),
          value: "GED",
        },
        {
          text: this.$t("BENEFICIOS"),
          value: "BENEFICIOS",
        },
        {
          text: this.$t("GESTAOTAREFAS"),
          value: "GESTAOTAREFAS",
        },
        {
          text: this.$t("LIVROOCORRENCIA"),
          value: "LIVROOCORRENCIA",
        },
        {
          text: this.$t("TRANSPORTEVALORES"),
          value: "TRANSPORTEVALORES",
        },
        {
          text: this.$t("ROTEIRIZADOR"),
          value: "ROTEIRIZADOR",
        },
        {
          text: this.$t("DOCUMENTACAOACESSORIAAUTOMATICA"),
          value: "DOCUMENTACAOACESSORIAAUTOMATICA",
        },
        {
          text: this.$t("GESTAOORCAMENTODESPESA"),
          value: "GESTAOORCAMENTODESPESA",
        },
        {
          text: this.$t("GCD"),
          value: "GCD",
        },
        {
          text: this.$t("GAH"),
          value: "GAH",
        },
        {
          text: this.$t("GFP"),
          value: "GFP",
        },
        {
          text: this.$t("GCV"),
          value: "GCV",
        },
        {
          text: this.$t("GCI"),
          value: "GCI",
        },
        {
          text: this.$t("GLT"),
          value: "GLT",
        },
        {
          text: this.$t("GTI"),
          value: "GTI",
        },
        {
          text: this.$t("LOCACAOEQUIPAMENTOSMEDICOS"),
          value: "LOCACAOEQUIPAMENTOSMEDICOS",
        },
      ],
      itensParametrizacoes: [
        {
          text: this.$t("JORNADA_TRABALHO"),
          value: "JORNADA_TRABALHO",
        },
        {
          text: this.$t("NOTURNA"),
          value: "NOTURNA",
        },
        {
          text: this.$t("NOTURNA_REDUZIDA"),
          value: "NOTURNA_REDUZIDA",
        },
        {
          text: this.$t("PROJECAO"),
          value: "PROJECAO",
        },
        {
          text: this.$t("FALTA_INJUSTIFICADA"),
          value: "FALTA_INJUSTIFICADA",
        },
        {
          text: this.$t("FALTA_JUSTIFICADA"),
          value: "FALTA_JUSTIFICADA",
        },
        {
          text: this.$t("ABONO"),
          value: "ABONO",
        },
        {
          text: this.$t("ATRASO"),
          value: "ATRASO",
        },
        {
          text: this.$t("EXTRA50"),
          value: "EXTRA50",
        },
        {
          text: this.$t("EXTRA100"),
          value: "EXTRA100",
        },
        {
          text: this.$t("FERIAS"),
          value: "FERIAS",
        },
        {
          text: this.$t("COMPENSAR"),
          value: "COMPENSAR",
        },
        {
          text: this.$t("EXTRA50INCOMPENSAVEL"),
          value: "EXTRA50INCOMPENSAVEL",
        },
        { text: this.$t("INTRAJORNADA"), value: "INTRAJORNADA" },
        { text: this.$t("INTERJORNADA"), value: "INTERJORNADA" },
        { text: this.$t("EXTRA50BANCOHORAS"), value: "EXTRA50BANCOHORAS" },

        { text: this.$t("TROCAPLANTAO"), value: "TROCAPLANTAO" },

        { text: this.$t("DSR_FALTA_INJUSTIFICADA"), value: "DSR_FALTA_INJUSTIFICADA" },
        { text: this.$t("DSR_HORAS_EXTRAS"), value: "DSR_HORAS_EXTRAS" },
        { text: this.$t("HORAS_TRABALHADAS_INTERMITENTE"), value: "HORAS_TRABALHADAS_INTERMITENTE" },
        { text: this.$t("NOTURNA_PRORROGADA"), value: "NOTURNA_PRORROGADA" },
        { text: this.$t("PERICULOSIDADE"), value: "PERICULOSIDADE" },
        { text: this.$t("INSALUBRIDADE"), value: "INSALUBRIDADE" },
        { text: this.$t("DESCONTOALIMENTACAO"), value: "DESCONTOALIMENTACAO" },
        { text: this.$t("DESCONTOTRANSPORTE"), value: "DESCONTOTRANSPORTE" },
        { text: this.$t("DESCONTOAJUDACUSTO"), value: "DESCONTOAJUDACUSTO" },
        { text: this.$t("CREDITOAJUDACUSTO"), value: "CREDITOAJUDACUSTO" },

        { text: this.$t("EXTRA60"), value: "EXTRA60" },
        { text: this.$t("EXTRA60INCOMPENSAVEL"), value: "EXTRA60INCOMPENSAVEL" },
        { text: this.$t("EXTRA70"), value: "EXTRA70" },
        { text: this.$t("SOBREAVISO"), value: "SOBREAVISO" },
        { text: this.$t("SALDOBANCOHORAS"), value: "SALDOBANCOHORAS" },
        { text: this.$t("SUSPENSAO"), value: "SUSPENSAO" },


      ],
      tiposFuncionarioAfastamentos: [
        {
          text: this.$t('ATESTADOMEDICO'),
          value: 'ATESTADOMEDICO',
        },
        {
          text: this.$t('INSS'),
          value: 'INSS',
        },
        {
          text: this.$t('FOLGAABONADA'),
          value: 'FOLGAABONADA',
        },
        {
          text: this.$t('FOLGACOMPENSATORIA'),
          value: 'FOLGACOMPENSATORIA',
        },
        {
          text: this.$t('SOBREAVISO'),
          value: 'SOBREAVISO',
        },
        {
          text: this.$t('TROCAPLANTAO'),
          value: 'TROCAPLANTAO',
        },

        {
          text: this.$t('GALA'),
          value: 'GALA',
        },
        {
          text: this.$t('JUSTICAELEITORAL'),
          value: 'JUSTICAELEITORAL',
        },
        {
          text: this.$t('FERIAS'),
          value: 'FERIAS',
        },
        {
          text: this.$t('LICENCAMATERNIDADE'),
          value: 'LICENCAMATERNIDADE',
        },
        {
          text: this.$t('LICENCAPATERNIDADE'),
          value: 'LICENCAPATERNIDADE',
        },
        {
          text: this.$t('SUSPENSAO'),
          value: 'SUSPENSAO',
        },
        {
          text: this.$t('HORASABONADAS'),
          value: 'HORASABONADAS',
        },
        {
          text: this.$t('HORASCOMPENSATORIAS'),
          value: 'HORASCOMPENSATORIAS',
        },
        {
          text: this.$t('AUSENCIAMANUAL'),
          value: 'AUSENCIAMANUAL',
        },
        {
          text: this.$t('PLANTAOEXTRAMANUAL'),
          value: 'PLANTAOEXTRAMANUAL',
        },
        {
          text: this.$t('RESETCICLOESCALA'),
          value: "RESETCICLOESCALA"
        },
        {
          text: this.$t('FOLGAABONADASEMDESCONTOBENEFICIOS'),
          value: 'FOLGAABONADASEMDESCONTOBENEFICIOS',
        },
        {
          text: this.$t('FERIADOMANUAL'),
          value: 'FERIADOMANUAL',
        },
        {
          text: this.$t('ABONARATRASO'),
          value: 'ABONARATRASO',
        },
        {
          text: this.$t('ADVERTENCIA'),
          value: 'ADVERTENCIA',
        },
        {
          text: this.$t('REDUCAOPROJECAO'),
          value: 'REDUCAOPROJECAO',
        },
        {
          text: this.$t('AJUSTESALDOBH'),
          value: 'AJUSTESALDOBH',
        },

      ],
      tiposPeriodoFolhaPagamento: [
        {
          text: this.$t('MENSAL'),
          value: 'MENSAL',
        },
        {
          text: this.$t('ADIANTAMENTOMENSAL'),
          value: 'ADIANTAMENTOMENSAL',
        },
        {
          text: this.$t('FERIAS'),
          value: 'FERIAS',
        },
        {
          text: this.$t('PRIMEIRAPARCELA13'),
          value: 'PRIMEIRAPARCELA13',
        },
        {
          text: this.$t('SEGUNDAPARCELA13'),
          value: 'SEGUNDAPARCELA13',
        },

        {
          text: this.$t('COMPLEMENTAR'),
          value: 'COMPLEMENTAR',
        },
        {
          text: this.$t('BENEFICIOS'),
          value: 'BENEFICIOS',
        },
        {
          text: this.$t('BENEFICIOSCOMPLEMENTAR'),
          value: 'BENEFICIOSCOMPLEMENTAR',
        },
        {
          text: this.$t('INFORMERENDIMENTOS'),
          value: 'INFORMERENDIMENTOS',
        }
      ],
      lotesFaturamento: [
        {
          text: this.$t('LOTEFATURAMENTO01'),
          value: 'LOTEFATURAMENTO01',
        },
        {
          text: this.$t('LOTEFATURAMENTO02'),
          value: 'LOTEFATURAMENTO02',
        },
        {
          text: this.$t('LOTEFATURAMENTO03'),
          value: 'LOTEFATURAMENTO03',
        },
        {
          text: this.$t('LOTEFATURAMENTO04'),
          value: 'LOTEFATURAMENTO04',
        },
        {
          text: this.$t('LOTEFATURAMENTO05'),
          value: 'LOTEFATURAMENTO05',
        },
        {
          text: this.$t('LOTEFATURAMENTO06'),
          value: 'LOTEFATURAMENTO06',
        },
        {
          text: this.$t('LOTEFATURAMENTO07'),
          value: 'LOTEFATURAMENTO07',
        },
        {
          text: this.$t('LOTEFATURAMENTO08'),
          value: 'LOTEFATURAMENTO08',
        },
        {
          text: this.$t('LOTEFATURAMENTO09'),
          value: 'LOTEFATURAMENTO09',
        },
        {
          text: this.$t('LOTEFATURAMENTO10'),
          value: 'LOTEFATURAMENTO10',
        },
        {
          text: this.$t('LOTEFATURAMENTO11'),
          value: 'LOTEFATURAMENTO11',
        },
        {
          text: this.$t('LOTEFATURAMENTO12'),
          value: 'LOTEFATURAMENTO12',
        },
        {
          text: this.$t('LOTEFATURAMENTO13'),
          value: 'LOTEFATURAMENTO13',
        },
        {
          text: this.$t('LOTEFATURAMENTO14'),
          value: 'LOTEFATURAMENTO14',
        },
        {
          text: this.$t('LOTEFATURAMENTO15'),
          value: 'LOTEFATURAMENTO15',
        },
        {
          text: this.$t('LOTEFATURAMENTO16'),
          value: 'LOTEFATURAMENTO16',
        },
        {
          text: this.$t('LOTEFATURAMENTO17'),
          value: 'LOTEFATURAMENTO17',
        },
        {
          text: this.$t('LOTEFATURAMENTO18'),
          value: 'LOTEFATURAMENTO18',
        },
        {
          text: this.$t('LOTEFATURAMENTO19'),
          value: 'LOTEFATURAMENTO19',
        },
        {
          text: this.$t('LOTEFATURAMENTO20'),
          value: 'LOTEFATURAMENTO20',
        },
        {
          text: this.$t('LOTEFATURAMENTO21'),
          value: 'LOTEFATURAMENTO21',
        },
        {
          text: this.$t('LOTEFATURAMENTO22'),
          value: 'LOTEFATURAMENTO22',
        },
        {
          text: this.$t('LOTEFATURAMENTO23'),
          value: 'LOTEFATURAMENTO23',
        },
        {
          text: this.$t('LOTEFATURAMENTO24'),
          value: 'LOTEFATURAMENTO24',
        },
        {
          text: this.$t('LOTEFATURAMENTO25'),
          value: 'LOTEFATURAMENTO25',
        },
        {
          text: this.$t('LOTEFATURAMENTO26'),
          value: 'LOTEFATURAMENTO26',
        },
        {
          text: this.$t('LOTEFATURAMENTO27'),
          value: 'LOTEFATURAMENTO27',
        },
        {
          text: this.$t('LOTEFATURAMENTO28'),
          value: 'LOTEFATURAMENTO28',
        },
        {
          text: this.$t('LOTEFATURAMENTO29'),
          value: 'LOTEFATURAMENTO29',
        },
        {
          text: this.$t('LOTEFATURAMENTO30'),
          value: 'LOTEFATURAMENTO30',
        },
        {
          text: this.$t('LOTEFATURAMENTO31'),
          value: 'LOTEFATURAMENTO31',
        },
      ],
      condicoesPagamento: [
        {
          text: this.$t('DIA05MESCORRENTE'),
          value: 'DIA05MESCORRENTE',
        },

        {
          text: this.$t('DIA10MESCORRENTE'),
          value: 'DIA10MESCORRENTE',
        },
        {
          text: this.$t('DIA12MESCORRENTE'),
          value: 'DIA12MESCORRENTE',
        },
        {
          text: this.$t('DIA15MESCORRENTE'),
          value: 'DIA15MESCORRENTE',
        },
        {
          text: this.$t('DIA25MESCORRENTE'),
          value: 'DIA25MESCORRENTE',
        },
        {
          text: this.$t('DIA17MESCORRENTE'),
          value: 'DIA17MESCORRENTE',
        },
        {
          text: this.$t('DIA20MESCORRENTE'),
          value: 'DIA20MESCORRENTE',
        },
        {
          text: this.$t('ULTIMODIAMESCORRENTE'),
          value: 'ULTIMODIAMESCORRENTE',
        },
        {
          text: this.$t('DIA01MESSUBSEQUENTE'),
          value: 'DIA01MESSUBSEQUENTE',
        },
        {
          text: this.$t('DIA03MESSUBSEQUENTE'),
          value: 'DIA03MESSUBSEQUENTE',
        },
        {
          text: this.$t('DIA05MESSUBSEQUENTE'),
          value: 'DIA05MESSUBSEQUENTE',
        },
        {
          text: this.$t('DIA10MESSUBSEQUENTE'),
          value: 'DIA10MESSUBSEQUENTE',
        },
        {
          text: this.$t('DIA12MESSUBSEQUENTE'),
          value: 'DIA12MESSUBSEQUENTE',
        },
        {
          text: this.$t('DIA15MESSUBSEQUENTE'),
          value: 'DIA15MESSUBSEQUENTE',
        },
        {
          text: this.$t('DIA17MESSUBSEQUENTE'),
          value: 'DIA17MESSUBSEQUENTE',
        },
        {
          text: this.$t('DIA20MESSUBSEQUENTE'),
          value: 'DIA20MESSUBSEQUENTE',
        },
        {
          text: this.$t('ULTIMODIAMESSUBSEQUENTE'),
          value: 'ULTIMODIAMESSUBSEQUENTE',
        },
        {
          text: this.$t('TRESVEZESDIAS051020MESSUBSEQUENTE'),
          value: 'TRESVEZESDIAS051020MESSUBSEQUENTE',
        },
        {
          text: this.$t('TRESVEZESDIAS031119MESSUBSEQUENTE'),
          value: 'TRESVEZESDIAS031119MESSUBSEQUENTE',
        },
        {
          text: this.$t('TRINTADIASAPOSEMISSAO'),
          value: 'TRINTADIASAPOSEMISSAO',
        },
        {
          text: this.$t('TRINTAEUMDIASAPOSEMISSAO'),
          value: 'TRINTAEUMDIASAPOSEMISSAO',
        },
        {
          text: this.$t('TRINTAECINCODIASAPOSEMISSAO'),
          value: 'TRINTAECINCODIASAPOSEMISSAO',
        },
        {
          text: this.$t('QUARENTADIASAPOSEMISSAO'),
          value: 'QUARENTADIASAPOSEMISSAO',
        },
        {
          text: this.$t('DEZDIASAPOSEMISSAO'),
          value: 'DEZDIASAPOSEMISSAO',
        },
      ],
      statusCalculoFaturamento: [
        {
          text: this.$t('PENDENTE'),
          value: 'PENDENTE',
        },
        {
          text: this.$t('DOCUMENTOGERADO'),
          value: 'DOCUMENTOGERADO',
        },
        {
          text: this.$t('DOCUMENTOCANCELADO'),
          value: 'DOCUMENTOCANCELADO',
        },
      ],
      tiposPeriodoBeneficio: [
        {
          text: this.$t('MENSAL'),
          value: 'MENSAL',
        },
        {
          text: this.$t('COMPLEMENTAR'),
          value: 'COMPLEMENTAR',
        },
      ],
    };
  },
};
