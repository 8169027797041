import Vue from "vue";
import App from "./App.vue";
import DashboardPlugin from "./plugins/dashboard-plugin";
import vuetify from "./plugins/vuetify";
import router from "./router";


import CampoCompetencia from "@/components/base/proprios/CampoCompetencia";
import CampoData from "@/components/base/proprios/CampoData";
import CampoDataHora from "@/components/base/proprios/CampoDataHora";
import ConfirmaAcao from "@/components/base/proprios/ConfirmaAcao";
import { formValidationRules } from "@/mixins/formValidationRules";
import Axios from "axios";
import "sweetalert2/dist/sweetalert2.min.css";

//import "sweetalert2/dist/sweetalert2.min.css";
//Componente de máscaras
import VueMask, { VueMaskDirective, VueMaskFilter } from "v-mask";
import VueSweetAlert2 from "vue-sweetalert2";
import VuetifyMask from "vuetify-mask";
import TutorialsList from "./components/TutorialsList";
import ConfirmButton from "./components/base/ConfirmButton";
import ErrorAlert from "./components/base/ErrorAlert";
import ReturnDetail from "./components/base/ReturnDetail";
import ConfirmationOverlay from "./components/base/proprios/ConfirmationOverlay";
import LoadingOverlay from "./components/base/proprios/LoadingOverlay";
import CampoCfopCidade from "./components/base/proprios/campos/CampoCfopCidade";
import CampoCfopEstado from "./components/base/proprios/campos/CampoCfopEstado";
import ChartBar from "./components/base/proprios/charts/ChartBar";
import Doughnut from "./components/base/proprios/charts/Doughnut";
import i18n from "./i18n";
import { checkPendingTutorialModal } from "./mixins/checkPendingTutorialModal";
import limpaMascara from "./mixins/limpaMascara";
import utils from "./mixins/utils";
import "./plugins/base";
import "./plugins/chartist";
import "./plugins/vee-validate";
import store from "./store";

Vue.config.productionTip = false;

// Photoswipe Gallery
import Photoswipe from "vue-pswipe";
Vue.use(Photoswipe);

// plugin setup
Vue.use(DashboardPlugin);

const CaixaLegenda = () => import("./components/base/CaixaLegenda");
Vue.component("CaixaLegenda", CaixaLegenda);


Vue.use(VuetifyMask);

Vue.use(VueSweetAlert2)
Vue.component("TutorialsList", TutorialsList);
Vue.component("LoadingOverlay", LoadingOverlay);
Vue.component("ChartBar", ChartBar);
Vue.component("Doughnut", Doughnut);
Vue.component("ConfirmationOverlay", ConfirmationOverlay);
Vue.component("ConfirmButton", ConfirmButton);
Vue.component("CampoCfopEstado", CampoCfopEstado);
Vue.component("CampoCfopCidade", CampoCfopCidade);
Vue.component("CampoData", CampoData);
Vue.component("CampoCompetencia", CampoCompetencia);
Vue.component("CampoDataHora", CampoDataHora);
Vue.component("ErrorAlert", ErrorAlert);
Vue.component("ReturnDetail", ReturnDetail);
Vue.component("ConfirmaAcao", ConfirmaAcao);
Vue.config.productionTip = false;
Vue.filter("dataParaLeitura", function (value) {
  if (!value) return;
  let stringData = dataHoraParaLeitura(value);
  return stringData;
});

Vue.filter("competenciaParaLeitura", function (value) {

  if (!value) return;
  let stringData = competenciaParaLeitura(value);
  return stringData;
});

Vue.filter("horaParaLeitura", function (value) {
  if (!value) return;
  let stringData = horaParaLeitura(value);
  return stringData;
});

Vue.filter("dataHoraParaLeituraComSegundo", function (value) {
  if (!value) return;
  let stringData = dataHoraParaLeituraComSegundo(value);
  return stringData;
});


function dataHoraParaLeitura(value) {
  if (!value) return;

  if (value.length === 8) {
    // Formato "HH:mm:ss"
    return value.substr(0, 5); // Retorna apenas "HH:mm"
  }

  if (value.length >= 10) {
    // Formato "YYYY-MM-DD HH:mm:ss" ou "YYYY-MM-DD"
    let stringData = value.substr(8, 2) + "/" + value.substr(5, 2) + "/" + value.substr(0, 4);
    let stringHora = value.length >= 16 ? value.substr(11, 2) + ":" + value.substr(14, 2) : "";

    return stringHora ? stringData + " " + stringHora : stringData;
  }

  return value; // Retorna o valor original se não for reconhecido
}

function dataHoraParaLeituraComSegundo(value) {
  if (!value) return;

  let stringData = value.substr(8, 2) + "/" + value.substr(5, 2) + "/" + value.substr(0, 4);
  let stringHora = value.substr(11, 2) + ":" + value.substr(14, 2) + ":" + value.substr(17, 2);

  if (value.length > 10) {
    return stringData + " " + stringHora;
  } else {
    return stringData;
  }
}

function horaParaLeitura(value) {
  if (!value) return;

  let stringHora = "";
  if (value.length > 10) {
    //let stringData = value.substr(8, 2) + "/" + value.substr(5, 2) + "/" + value.substr(0, 4);
    stringHora = value.substr(11, 2) + ":" + value.substr(14, 2);
  } else {
    stringHora = value.substr(0, 2) + ":" + value.substr(3, 2);
  }
  return stringHora;
}

function competenciaParaLeitura(value) {
  if (!value) return;

  let stringData = value.substr(5, 2) + "/" + value.substr(0, 4);
  console.log("competenciapraleitura", stringData);
  return stringData;
}


//Filtros (Funções de formatação de string)
Vue.filter("dataHoraParaLeitura", function (value) {
  if (!value) return;
  let stringData = dataHoraParaLeitura(value);
  return stringData;
});

Vue.filter("numeroTresCasasDecimais", function (value) {
  let zero = (0).toLocaleString("pt-br");

  if (!value) return zero;

  let valueNumber = Number(value);
  let string = valueNumber.toLocaleString("pt-br");

  return string;
});

Vue.filter("formatoNumero", function (value) {
  let zero = (0).toLocaleString("pt-br");

  if (!value) return zero;

  let valueNumber = Number(value);
  let string = valueNumber.toLocaleString("pt-br");

  return string;
});

Vue.filter("formatoMonetario", function (value) {
  let zero = (0).toLocaleString("pt-br", {
    style: "currency",
    currency: "BRL",
  });

  if (!value) return zero;

  let valueNumber = Number(value);
  let string = valueNumber.toLocaleString("pt-br", {
    style: "currency",
    currency: "BRL",
  });

  return string;
});
Vue.filter("formatoPercentual", function (value) {
  // let zero = (0).toLocaleString("pt-br", {
  //   style: "percent",
  //   currency: "BRL",
  //   length: 4,
  //   precision: 2,
  // });

  // if (!value) return zero;

  // let valueNumber = Number(value);
  // if (valueNumber > 1) {
  //   valueNumber = valueNumber / 100;
  // }
  // let string = valueNumber.toLocaleString("pt-br", {
  //   style: "percent",
  //   currency: "BRL",
  //   length: 4,
  //   precision: 2,
  // });
  let valueNumber = Number(value);
  if (value === undefined) {
    valueNumber = 0;
  }
  valueNumber.toFixed(2);
  let string = value + "%";

  return string;
});
Vue.filter("formatoPercentualExato", function (value) {
  // let zero = (0).toLocaleString("pt-br", {
  //   style: "percent",
  //   currency: "BRL",
  //   length: 4,
  //   precision: 2,
  // });

  // if (!value) return zero;

  // let valueNumber = Number(value);
  // let string = valueNumber.toLocaleString("pt-br", {
  //   style: "percent",
  //   currency: "BRL",
  //   length: 4,
  //   precision: 2,
  // });
  let valueNumber = Number(value);
  if (value === undefined) {
    valueNumber = 0;
  }
  valueNumber.toFixed(4);
  let string = value + "%";

  return string;
});
Vue.filter("capitalize", function (value) {
  return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
});

Vue.filter("formatoNumeroDecimal", function (value) {
  if (!value) return '0,00';

  let valueNumber = Number(value);
  let string = valueNumber.toLocaleString("pt-BR", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  return string;
});

Vue.filter("formatoNumeroInteiro", function (value) {
  if (!value) return '0';

  let valueNumber = Number(value);
  let string = valueNumber.toLocaleString("pt-BR", {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });

  return string;
});

Vue.use(VueMask);

Vue.directive("mask", VueMaskDirective);
Vue.filter("VMask", VueMaskFilter);

//Configurações Axios
const baseURL = "https://appcajupe.com:8443/";
//const baseURL = "http://localhost:8080/";
Axios.defaults.baseURL = baseURL;
Axios.defaults.timeout = 1800000;
window.baseApiURL = baseURL;

const token = localStorage.getItem("user-token");
if (token) {
  Axios.defaults.headers.common["Authorization"] = token;
  store.dispatch("USER_REQUEST");
}

//Diretivas personalizadas
Vue.directive("can", {
  bind: function (el, binding, vnode) {
    if (!binding.value || !store.state.permissoes) {
      return;
    }

    if (
      !store.state.permissoes.includes(
        binding.value.replace(/'/g, "").replace(/"/g, "")
      )
    ) {
      vnode.elm.style.display = "none";
    }
  },
});

Vue.mixin(checkPendingTutorialModal);
Vue.mixin(limpaMascara);
Vue.mixin(formValidationRules);
Vue.mixin(utils);

new Vue({
  router,
  vuetify,
  store,
  Axios,
  i18n,
  data() {
    return {
      expression: "",
    };
  },
  render: (h) => h(App),

}).$mount("#app");