<template>
  <v-text-field
    v-model="cep"
    :label="$t('CEP')"
    v-mask="'##.###-###'"
    name="cep"
    @blur="buscaCep"
    class="input-style font-size-input text-light-input placeholder-light input-icon"
    dense
    flat
    filled
    solo
  />
</template>

<script>
import DataService from "@/services/ComumDataService";
import { cachedCidades } from "@/services/cacheCidades";

export default {
  name: "BuscaCep",
  props: {
    cepProp: { type: String, required: true },
  },
  data() {
    return {
      cep: this.cepProp,
    };
  },
  mounted() {
    this.getCidades();
  },
  watch: {
    cepProp(val) {
      this.cep = val;
    },
    cep(val) {
      this.$emit("cep", val);
    },
  },
  methods: {
    async buscaCep() {
      if (this.cep.length >= 8) {
        try {
          const response = await DataService.buscaCep(
            this.limpaMascara(this.cep)
          );
          const { localidade, uf, logradouro, bairro } = response.data;
          const cidadeResponse = await DataService.findCidade({
            cidade: localidade,
            uf,
          });
          this.$emit("cidade", cidadeResponse.data);
          this.$emit("logradouro", logradouro);
          this.$emit("bairro", bairro);
          this.$emit("endereco", {
            cidade: cidadeResponse.data,
            logradouro: logradouro,
            bairro: bairro,
          });
        } catch (error) {
          console.error("Erro na busca de CEP:", error);
        }
      }
    },
    async getCidades() {
      if (cachedCidades.length > 0) {
        this.$emit("cidades", cachedCidades);
        return;
      }
      try {
        const response = await DataService.getCidades();
        cachedCidades.push(...response.data);
        this.$emit("cidades", cachedCidades);
      } catch (error) {
        console.error("Erro ao buscar cidades:", error);
      }
    },
    limpaMascara(valor) {
      return valor.replace(/\D/g, "");
    },
  },
};
</script>

<style scoped>
/* Seus estilos */
</style>