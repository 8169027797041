import createNumberMask from "text-mask-addons/dist/createNumberMask";
const currencyMask = createNumberMask({
  prefix: "R$",
  allowDecimal: true,
  includeThousandsSeparator: true,
  allowNegative: false,
});

export default {
  data() {
    return {
      monetaryMask: currencyMask
    }
  },

  methods: {
    // Função auxiliar para converter com segurança para string
    safeToString(value) {
      return (value !== null && value !== undefined) ? value.toString() : "";
    },

    limpaMascara(payload) {
      let recurso = payload;

      // Trata o campo email
      if (recurso.email) {
        let value = recurso.email;
        if (value.match(/[a-zA-Z]/)) {
          // Mantém o valor original se conter letras
        } else {
          value = value.replace(/\D/g, '');
          recurso.email = value;
        }
      }

      // Retirar atributos não essenciais
      if (recurso.funcionario) {
        recurso.funcionario = { id: recurso.funcionario.id };
      }

      // Ajuste de formatação para datas
      if (recurso.seguroInicio) {
        recurso.seguroInicio = recurso.seguroInicio.replace(" ", "T");
      }
      if (recurso.seguroVencimento) {
        recurso.seguroVencimento = recurso.seguroVencimento.replace(" ", "T");
      }
      if (recurso.tacografoData) {
        recurso.tacografoData = recurso.tacografoData.replace(" ", "T");
      }
      if (recurso.tacografoVencimento) {
        recurso.tacografoVencimento = recurso.tacografoVencimento.replace(" ", "T");
      }

      // Se o recurso for uma string, limpa caracteres especiais
      if (typeof recurso === "string") {
        let str = this.safeToString(recurso);
        str = str.replace(/\$/gi, "");
        str = str.replace(/\,/gi, "");
        str = str.replace(/\./gi, "");
        str = str.replace(/\-/gi, "");
        str = str.replace(/([A-z])/, "");
        console.log("limpaString", str);
        recurso = str;
      }

      // Processa diversos campos numéricos ou monetários
      if (recurso.salarioBase) {
        let str = this.safeToString(recurso.salarioBase);
        str = str.replace(/\$/gi, "");
        str = str.replace(/\,/gi, "");
        str = str.replace(/([A-z])/, "");
        recurso.salarioBase = str;
      }

      if (recurso.valor) {
        let str = this.safeToString(recurso.valor);
        str = str.replace(/\$/gi, "");
        str = str.replace(/\,/gi, "");
        str = str.replace(/([A-z])/, "");
        recurso.valor = str;
      }

      if (recurso.saldo) {
        let str = this.safeToString(recurso.saldo);
        str = str.replace(/\$/gi, "");
        str = str.replace(/\,/gi, "");
        str = str.replace(/\./gi, "");
        str = str.replace(/([A-z])/, "");
        recurso.saldo = str;
      }

      if (recurso.juros) {
        let str = this.safeToString(recurso.juros);
        str = str.replace(/\$/gi, "");
        str = str.replace(/\,/gi, "");
        str = str.replace(/\./gi, "");
        str = str.replace(/([A-z])/, "");
        recurso.juros = str;
      }

      if (recurso.multa) {
        let str = this.safeToString(recurso.multa);
        str = str.replace(/\$/gi, "");
        str = str.replace(/\,/gi, "");
        str = str.replace(/\./gi, "");
        str = str.replace(/([A-z])/, "");
        recurso.multa = str;
      }

      if (recurso.desconto) {
        let str = this.safeToString(recurso.desconto);
        str = str.replace(/\$/gi, "");
        str = str.replace(/\,/gi, "");
        str = str.replace(/\./gi, "");
        str = str.replace(/([A-z])/, "");
        recurso.desconto = str;
      }

      if (recurso.outrosValores) {
        let str = this.safeToString(recurso.outrosValores);
        str = str.replace(/\$/gi, "");
        str = str.replace(/\,/gi, "");
        str = str.replace(/\./gi, "");
        str = str.replace(/([A-z])/, "");
        recurso.outrosValores = str;
      }

      // Processa telefones se existir
      if (recurso.telefones && recurso.telefones.length > 0) {
        let telefones = [];
        recurso.telefones.forEach(telefone => {
          if (telefone) {
            if (telefone.fone !== undefined && telefone.fone !== null) {
              let str = this.safeToString(telefone.fone);
              str = str.replace(/\$/gi, "");
              str = str.replace(/\,/gi, "");
              str = str.replace(/\./gi, "");
              str = str.replace(/\-/gi, "");
              str = str.replace(/\(/gi, "");
              str = str.replace(/\)/gi, "");
              str = str.replace(/([A-z])/, "");
              telefone.fone = str;
            }
            telefones.push(telefone);
          }
        });
        recurso.telefones = telefones;
      }

      // Processa campo rg
      if (recurso.rg) {
        let str = this.safeToString(recurso.rg);
        str = str.replace(/\$/gi, "");
        str = str.replace(/\,/gi, "");
        str = str.replace(/\./gi, "");
        str = str.replace(/\-/gi, "");
        str = str.replace(/([A-z])/, "");
        recurso.rg = str;
      }

      // Processa campos nRegistro e nregistro
      if (recurso.nRegistro) {
        let str = this.safeToString(recurso.nRegistro);
        str = str.replace(/\$/gi, "");
        str = str.replace(/\,/gi, "");
        str = str.replace(/\./gi, "");
        str = str.replace(/\-/gi, "");
        str = str.replace(/([A-z])/, "");
        recurso.nRegistro = str;
      }
      if (recurso.nregistro) {
        let str = this.safeToString(recurso.nregistro);
        str = str.replace(/\$/gi, "");
        str = str.replace(/\,/gi, "");
        str = str.replace(/\./gi, "");
        str = str.replace(/\-/gi, "");
        str = str.replace(/([A-z])/, "");
        recurso.nregistro = str;
      }

      // Processa campos nIe e nIm
      if (recurso.nIe) {
        let str = this.safeToString(recurso.nIe);
        str = str.replace(/\$/gi, "");
        str = str.replace(/\,/gi, "");
        str = str.replace(/\./gi, "");
        str = str.replace(/\-/gi, "");
        str = str.replace(/([A-z])/, "");
        recurso.nIe = str;
      }
      if (recurso.nIm) {
        let str = this.safeToString(recurso.nIm);
        str = str.replace(/\$/gi, "");
        str = str.replace(/\,/gi, "");
        str = str.replace(/\./gi, "");
        str = str.replace(/\-/gi, "");
        str = str.replace(/([A-z])/, "");
        recurso.nIm = str;
      }

      if (recurso.pis) {
        let str = this.safeToString(recurso.pis);
        str = str.replace(/\$/gi, "");
        str = str.replace(/\,/gi, "");
        str = str.replace(/\./gi, "");
        str = str.replace(/\-/gi, "");
        str = str.replace(/([A-z])/, "");
        recurso.pis = str;
      }

      if (recurso.gestorCpf) {
        let str = this.safeToString(recurso.gestorCpf);
        str = str.replace(/\$/gi, "");
        str = str.replace(/\,/gi, "");
        str = str.replace(/\./gi, "");
        str = str.replace(/\-/gi, "");
        str = str.replace(/([A-z])/, "");
        recurso.gestorCpf = str;
      }

      if (recurso.cpf) {
        let str = this.safeToString(recurso.cpf);
        str = str.replace(/\$/gi, "");
        str = str.replace(/\,/gi, "");
        str = str.replace(/\./gi, "");
        str = str.replace(/\-/gi, "");
        str = str.replace(/([A-z])/, "");
        recurso.cpf = str;
      }

      if (recurso.cep) {
        let str = this.safeToString(recurso.cep);
        str = str.replace(/\$/gi, "");
        str = str.replace(/\,/gi, "");
        str = str.replace(/\./gi, "");
        str = str.replace(/\-/gi, "");
        str = str.replace(/([A-z])/, "");
        recurso.cep = str;
      }

      if (recurso.pessoa) {
        if (recurso.pessoa.telefones) {
          let telefones = [];
          recurso.pessoa.telefones.forEach(telefone => {
            if (telefone.fone !== undefined && telefone.fone !== null) {
              let str = this.safeToString(telefone.fone);
              str = str.replace(/\$/gi, "");
              str = str.replace(/\,/gi, "");
              str = str.replace(/\./gi, "");
              str = str.replace(/\-/gi, "");
              str = str.replace(/\(/gi, "");
              str = str.replace(/\)/gi, "");
              str = str.replace(/([A-z])/, "");
              telefone.fone = str;
            }
            telefones.push(telefone);
          });
          recurso.pessoa.telefones = telefones;
        }

        if (recurso.pessoa.cep) {
          let str = this.safeToString(recurso.pessoa.cep);
          str = str.replace(/\$/gi, "");
          str = str.replace(/\,/gi, "");
          str = str.replace(/\./gi, "");
          str = str.replace(/\-/gi, "");
          str = str.replace(/([A-z])/, "");
          recurso.pessoa.cep = str;
        }

        if (recurso.pessoa.cpf) {
          let str = this.safeToString(recurso.pessoa.cpf);
          str = str.replace(/\$/gi, "");
          str = str.replace(/\,/gi, "");
          str = str.replace(/\./gi, "");
          str = str.replace(/\-/gi, "");
          str = str.replace(/([A-z])/, "");
          recurso.pessoa.cpf = str;
        }

        if (recurso.pessoa.rg) {
          let str = this.safeToString(recurso.pessoa.rg);
          str = str.replace(/\$/gi, "");
          str = str.replace(/\,/gi, "");
          str = str.replace(/\./gi, "");
          str = str.replace(/\-/gi, "");
          str = str.replace(/([A-z])/, "");
          recurso.pessoa.rg = str;
        }
      }

      if (recurso.enderecos && recurso.enderecos.length > 0) {
        let enderecos = [];
        recurso.enderecos.forEach(endereco => {
          if (endereco.cep !== undefined && endereco.cep !== null) {
            let str = this.safeToString(endereco.cep);
            str = str.replace(/\$/gi, "");
            str = str.replace(/\,/gi, "");
            str = str.replace(/\./gi, "");
            str = str.replace(/\-/gi, "");
            str = str.replace(/([A-z])/, "");
            endereco.cep = str;
            enderecos.push(endereco);
          }
        });
      }

      return recurso;
    }
  }
};