<template>
  <EditarBase
    nomeSingular="FuncionarioAvisoPrevio"
    nomePlural="FuncionarioAvisoPrevios"
    @recurso="recurso = $event"
    @submitSuccess="emitSubmitSuccess"
    @submitError="emitSubmitError"
    @closeModalEdit="closeModalEdit"
  >
    <v-col cols="12">
      <v-row>
        <v-col cols="3">
          <v-span class="opacity-5 text-dark">
            {{ $t("Matricula") }}
          </v-span>
          <v-text-field
            v-model="recurso.funcionario.matricula"
            :label="$t('Matricula')"
            name="matricula"
            class="input-style font-size-input text-light-input placeholder-light input-icon"
            dense
            flat
            filled
            solo
            disabled
          >
          </v-text-field>
        </v-col>
        <v-col cols="9">
          <v-span class="opacity-5 text-dark">
            {{ $t("Nome") }}
          </v-span>
          <v-text-field
            v-model="recurso.funcionario.nomeSocial"
            :label="$t('Nome')"
            name="descricao"
            class="input-style font-size-input text-light-input placeholder-light input-icon"
            dense
            flat
            filled
            solo
            disabled
          >
          </v-text-field>
        </v-col>

        <v-col cols="12" md="4" id="campo-dt-inicio">
          <v-span class="opacity-5 text-dark">
            {{ $t("Inicio_do_periodo") }}
          </v-span>
          <CampoData
            :payload="recurso.inicio"
            @date="recurso.inicio = $event"
            label="Inicio_do_periodo"
          />
        </v-col>
        <v-col cols="12" md="4" id="campo-dt-encerramento">
          <v-span class="opacity-5 text-dark">
            {{ $t("Final_do_periodo") }}
          </v-span>
          <CampoData
            :payload="recurso.encerramento"
            @date="recurso.encerramento = $event"
            label="Final_do_periodo"
          />
        </v-col>

        <v-col cols="12" md="4">
          <v-span class="opacity-5 text-dark">
            {{ $t("origemAvisoPrevio") }}
          </v-span>
          <v-select
            id="origemAvisoPrevio"
            v-model="recurso.origemAvisoPrevio"
            :items="tiposOrigemAvisoPrevio"
            :label="$t('origemAvisoPrevio')"
            class="input-style font-size-input text-light-input placeholder-light input-icon"
            dense
            flat
            filled
            solo
          ></v-select>
        </v-col>

        <v-col cols="12" md="4">
          <v-span class="opacity-5 text-dark">
            {{ $t("tipoAvisoPrevio") }}
          </v-span>
          <v-select
            id="tipoAvisoPrevio"
            v-model="recurso.tipoAvisoPrevio"
            :items="tiposAvisoPrevio"
            :label="$t('tipoAvisoPrevio')"
            class="input-style font-size-input text-light-input placeholder-light input-icon"
            dense
            flat
            filled
            solo
          ></v-select>
        </v-col>
        <v-col
          cols="12"
          md="4"
          v-if="
            recurso.origemAvisoPrevio === 'EMPRESA' &&
            recurso.tipoAvisoPrevio === 'TRABALHADO'
          "
        >
          <v-span class="opacity-5 text-dark">
            {{ $t("beneficioAvisoPrevio") }}
          </v-span>
          <v-select
            id="beneficioAvisoPrevio"
            v-model="recurso.beneficioAvisoPrevio"
            :items="tiposBeneficioAvisoPrevio"
            :label="$t('beneficioAvisoPrevio')"
            class="input-style font-size-input text-light-input placeholder-light input-icon"
            dense
            flat
            filled
            solo
          ></v-select>
        </v-col>

        <v-col cols="12" md="4" id="dataHomologacao">
          <v-span class="opacity-5 text-dark">
            {{ $t("dataHomologacao") }}
          </v-span>
          <CampoData
            :payload="recurso.dataHomologacao"
            @date="recurso.dataHomologacao = $event"
            label="dataHomologacao"
          />
        </v-col>

        <v-col cols="12" class="">
          <div>
            <v-span class="opacity-5 text-dark">
              {{ $t("Observacao") }}
            </v-span>
            <v-text-field
              v-model="recurso.observacao"
              :label="$t('Observacao')"
              name="Justificativa"
              class="input-style font-size-input text-light-input placeholder-light input-icon"
              dense
              flat
              filled
              solo
            >
            </v-text-field>
          </div>
        </v-col>
      </v-row>
    </v-col>
  </EditarBase>
</template>

<script>
import EditarBase from "@/components/base/Crud/EditarBase";

import FuncionarioAvisoPrevioDataService from "@/services/pessoal/FuncionarioAvisoPrevioDataService";
const DataService = FuncionarioAvisoPrevioDataService;

import createNumberMask from "text-mask-addons/dist/createNumberMask";
const currencyMask = createNumberMask({
  prefix: "R$",
  allowDecimal: true,
  includeThousandsSeparator: true,
  allowNegative: false,
});

export default {
  name: "EditarFuncionarioAvisoPrevio",
  components: { EditarBase },
  props: {
    recurso: Object,
  },
  data() {
    return {
      DataService: DataService,
      submited: false,
      mask: currencyMask,

      recurso: {
        descricaoFuncionario: "",
      },
      salBase: "12",
      errors: [],

      itensStatusComum: [
        { text: this.$t("Ativo"), value: "ATIVO" },
        { text: this.$t("Inativo"), value: "INATIVO" },
      ],
      tiposAvisoPrevio: [
        { text: this.$t("TRABALHADO"), value: "TRABALHADO" },
        { text: this.$t("INDENIZADO"), value: "INDENIZADO" },
      ],
      tiposBeneficioAvisoPrevio: [
        { text: this.$t("REDUCAODUASHORAS"), value: "REDUCAODUASHORAS" },
        { text: this.$t("REDUCAOSETEDIAS"), value: "REDUCAOSETEDIAS" },
      ],

      tiposOrigemAvisoPrevio: [
        { text: this.$t("EMPRESA"), value: "EMPRESA" },
        { text: this.$t("COLABORADOR"), value: "COLABORADOR" },
      ],
    };
  },

  computed: {
    salarioBaseSemMascara: function () {
      let str = this.recurso.salarioBase;

      str = str.toString();
      str = str.replace("$", "");
      str = str.replace(/\,/gi, "");
      str = str.replace(/([A-z])/, "");

      console.log(str);
      return str;
    },
  },

  mounted() {
    //this.getRecurso();
  },

  methods: {
    emitSubmitSuccess() {
      this.$emit("submitSuccess");
    },
    emitSubmitError() {
      this.$emit("submitError");
    },
    closeModalEdit() {
      this.$emit("closeModalEdit");
    },
    submitForm(event) {
      DataService.update({
        descricao: this.recurso.descricao,
        statusComum: this.recurso.statusComum,
        salarioBase: this.salarioBaseSemMascara,
        id: this.recurso.id,
      })
        .then((response) => {
          this.erros = [];
          this.submited = true;
        })
        .catch((error) => {
          window.scrollTo({ top: 0, left: 0, behavior: "smooth" });

          this.errors = error.response.data.errors;
        });
    },

    getRecurso() {
      DataService.find(this.recurso.id)
        .then((response) => {
          this.recurso.descricaoFuncionario =
            response.data.funcionario.matricula +
            " - " +
            response.data.funcionario.nomeSocial;
          this.recurso = response.data;
        })
        .catch((error) => {
          window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
          console.log(error);
          this.errors.push({ field: "ID", message: "Usuário não encontrado" });
        });
    },

    mascaraMonetaria(val) {
      val = this.$t("R$") + " " + val;
    },
  },
};
</script>

<style scoped>
</style>
