<template>
  <div>
    <ErrorAlert :errors="errors"></ErrorAlert>
    <div class="text-center" v-if="loading">
      <v-progress-circular
        :size="70"
        :width="7"
        color="#67748e"
        indeterminate
      ></v-progress-circular>
    </div>
    <v-container fluid class="px-6 py-0">
      <ErrorAlert :errors="errors" />

      <v-dialog
        transition="dialog-bottom-transition"
        max-width="900"
        v-model="modalPeriodo"
        persistent
      >
        <v-card>
          <v-card-title class="d-flex">
            <v-btn
              :disabled="loading"
              class="ma-1 font-weight-bold text-none btn-primary bg-gradient-primary py-3 px-6 ms-3"
              height="39"
              to="/dashboard/funcionarioPontoPeriodos/cadastro"
              v-can="'ROLE_FuncionarioPontoPeriodo_Insert'"
            >
              <v-icon left dark> mdi-plus-circle </v-icon>
              {{ $t("Cadastrar_periodo") }}
            </v-btn>
          </v-card-title>
          <v-card-text>
            <TabelaSelecionaRecurso
              @recurso="periodoSelecionado = $event"
              url="funcionarioPontoPeriodos"
              titulo="Periodo"
              :headers="[
                {
                  text: this.$t('Inicio'),
                  value: 'inicioPeriodo',
                  sortable: 'false',
                },
                {
                  text: this.$t('Final'),
                  value: 'finalPeriodo',
                  sortable: 'false',
                },
              ]"
            />
          </v-card-text>
          <v-card-actions class="justify-end">
            <v-btn
              @click="closeModalPeriodo()"
              height="39"
              class="font-weight-bold text-capitalize btn-ls btn-secondary bg-gradient-light py-3 px-6"
              >{{ $t("Cancelar") }}</v-btn
            >
            <v-btn
              :loading="loading"
              id="btn-confirmar"
              @click="retrieveData()"
              class="ma-1 font-weight-bold text-none btn-primary bg-gradient-primary py-3 px-6 ms-3"
              height="39"
              >{{ $t("Confirmar") }}</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="modalSelecionaArquivo" max-width="550">
        <v-card>
          <v-card-title>
            {{ $t("Importacao") }}
          </v-card-title>
          <v-col cols="12" md="12">
            <v-select
              :items="[
                { text: $t('AFD'), value: 'AFD' },
                { text: $t('AFDT'), value: 'AFDT' },
                { text: $t('CSV'), value: 'CSV' },
                { text: $t('ControlID'), value: 'ControlID' },
              ]"
              :label="$t('Tipo_importacao')"
              name="tipoImportacao"
              item-value="value"
              v-model="tipoImportacao"
              :disabled="loading"
              hide-details
              class="input-style font-size-input text-light-input placeholder-light input-icon"
              flat
              filled
              solo
            ></v-select>
          </v-col>
          <v-col cols="12" md="12">
            <v-card-actions>
              <input type="file" ref="doc" />
              <div>{{ statusReader }}</div>
            </v-card-actions>
          </v-col>
          <v-btn
            class="ma-1 font-weight-bold text-none btn-primary bg-gradient-primary py-3 px-6 ms-3"
            height="39"
            @click="importExternal()"
            :loading="loading"
          >
            {{ $t("Confirmar") }}
          </v-btn>
          <v-btn
            height="39"
            class="font-weight-bold text-capitalize btn-ls btn-secondary bg-gradient-light py-3 px-6"
            @click="modalSelecionaArquivo = false"
          >
            {{ $t("Cancelar") }}
          </v-btn>
          <v-btn
            v-if="this.tipoImportacao === 'CSV'"
            height="39"
            class="ma-1 font-weight-bold text-capitalize btn-ls btn-secondary bg-gradient-light py-3 px-6"
            onclick="window.location ='https://app-objetivar-img.s3.amazonaws.com/system_files/Cajupe_Modelo_Imp_Reg_Ponto.csv';"
            :loading="loading"
          >
            {{ $t("Arquivo_modelo") }}
          </v-btn>
        </v-card>
      </v-dialog>

      <!-- Modal confirmar delete -->
      <v-dialog
        v-model="modalConfirmarDelete"
        persistent
        max-width="350"
        class="dialog-deletado"
      >
        <v-card>
          <v-card-title class="headline">{{ $t("Tem_certeza?") }}</v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn
              class="ma-1 font-weight-bold text-none btn-primary bg-gradient-primary py-3 px-6 ms-3"
              height="39"
              @click="modalConfirmarDelete = false"
            >
              {{ $t("nao") }}
            </v-btn>

            <v-btn
              class="ma-1 font-weight-bold text-none btn-primary bg-gradient-primary py-3 px-6 ms-3"
              height="39"
              @click="deleteRecurso"
              :loading="loading"
            >
              {{ $t("Confirmar") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog
        v-model="modalInsert"
        persistent
        max-width="600px"
        :loading="loading"
      >
        <v-card>
          <v-card-title>
            <span class="text-h5"> {{ $t("Registro") }}</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" class="d-flex">
                  <v-autocomplete
                    v-model="recurso.matricula"
                    :items="this.todosFuncionarios"
                    item-text="nomeSocial"
                    label="Funcionario"
                    item-value="matricula"
                    hide-details
                    class="input-style font-size-input text-light-input placeholder-light input-icon"
                    flat
                    filled
                    solo
                  >
                  </v-autocomplete>
                </v-col>
                <v-col cols="12" class="d-flex">
                  <CampoDataHora
                    :label="$t('Data_e_hora')"
                    @date="recurso.data = $event"
                  />
                </v-col>

                <v-col
                  cols="12"
                  class=""
                  v-if="!registroPontoTravarJustificativa"
                >
                  <div>
                    <v-text-field
                      v-model="recurso.justificativa"
                      :label="$t('Justificativa')"
                      name="Justificativa"
                      hide-details
                      class="input-style font-size-input text-light-input placeholder-light input-icon"
                      flat
                      filled
                      solo
                    >
                    </v-text-field>
                  </div>
                </v-col>

                <v-col
                  cols="12"
                  class=""
                  v-if="registroPontoTravarJustificativa"
                >
                  <v-select
                    v-model="recurso.justificativa"
                    :items="splitItemsRegistroPontoJustificativa"
                    :label="$t('Justificativa')"
                  ></v-select>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              height="39"
              class="font-weight-bold text-capitalize btn-ls btn-secondary bg-gradient-light py-3 px-6"
              @click="closeModalInsert()"
              :loading="loading"
            >
              {{ $t("Cancelar") }}
            </v-btn>
            <v-btn
              class="ma-1 font-weight-bold text-none btn-primary bg-gradient-primary py-3 px-6 ms-3"
              height="39"
              @click="insert()"
              :loading="loading"
            >
              {{ $t("Enviar") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog
        v-model="modalUpdate"
        persistent
        max-width="600px"
        :loading="loading"
      >
        <v-card>
          <v-card-title>
            <span class="text-h5"> {{ $t("Atualizar_competencia") }}</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" class="d-flex">
                  <CampoData
                    :label="$t('Competencia')"
                    @date="recursoEdit.competencia = $event"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              height="39"
              class="font-weight-bold text-capitalize btn-ls btn-secondary bg-gradient-light py-3 px-6"
              @click="closeModalUpdate()"
              :loading="loading"
            >
              {{ $t("Cancelar") }}
            </v-btn>
            <v-btn
              class="ma-1 font-weight-bold text-none btn-primary bg-gradient-primary py-3 px-6 ms-3"
              height="39"
              @click="update()"
              :loading="loading"
            >
              {{ $t("Enviar") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- Modal Delete sucesso -->
      <div id="dialogDeletar">
        <v-dialog v-model="deletado" persistent max-width="350">
          <v-card>
            <v-card-title class="headline">
              {{ $t("deletado_sucesso") }}
            </v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                class="ma-1 font-weight-bold text-none btn-primary bg-gradient-primary py-3 px-6 ms-3"
                height="39"
                @click="deletado = false"
                :loading="loading"
              >
                {{ $t("Confirmar") }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>

      <v-row class="mt-8">
        <v-row>
          <v-btn
            v-if="botaoCadastro"
            class="ma-1 font-weight-bold text-none btn-primary bg-gradient-primary py-3 px-6 ms-3"
            height="39"
            @click="confirmaInsert()"
            @click.stop="modalInsert = true"
            :loading="loading"
            v-can="'ROLE_FuncionarioPonto_Insert'"
            id="btn--cadastro"
          >
            <v-icon left dark> mdi-plus-circle </v-icon>
            {{ $t("Novo_registro") }}
          </v-btn>

          <v-btn
            id="periodo"
            :loading="loading"
            @click.stop="modalPeriodo = true"
            class="ma-1 font-weight-bold text-none btn-primary bg-gradient-primary py-3 px-6 ms-3"
            height="39"
          >
            <v-icon left dark> mdi-table-sync </v-icon>
            {{ $t("Alterar_periodo") }}
          </v-btn>

          <v-btn
            :loading="loading"
            @click.stop="showimportExternal()"
            class="ma-1 font-weight-bold text-none btn-primary bg-gradient-primary py-3 px-6 ms-3"
            height="39"
            dark
            id="btn-import-payroll"
            v-can="'ROLE_FuncionarioPonto_Insert'"
          >
            <v-icon left> mdi-file-upload-outline </v-icon>
            {{ $t("Importar") }}
          </v-btn>
          <v-btn
            :loading="loading"
            @click.stop="exportarAfd()"
            class="ma-1 font-weight-bold text-none btn-primary bg-gradient-primary py-3 px-6 ms-3"
            height="39"
            dark
            id="btn-export-registers"
            v-can="'ROLE_FuncionarioPonto_List'"
          >
            <v-icon left> mdi-file-download-outline </v-icon>
            {{ $t("Exportar AFD") }}
          </v-btn>
        </v-row>
        <v-col cols="12">
          <template>
            <div>
              <v-card class="card-shadow border-radius-xl">
                <div class="card-header-padding">
                  <div class="d-flex align-center">
                    <v-col cols="12" md="2">
                      <v-text-field
                        hide-details
                        class="input-style font-size-input text-light-input placeholder-light input-icon"
                        dense
                        flat
                        filled
                        solo
                        v-model="search.matricula"
                        :label="$t('Matricula')"
                        name="matricula"
                      >
                      </v-text-field>
                    </v-col>

                    <v-col cols="12" md="4">
                      <v-text-field
                        v-model="search.nome"
                        :label="$t('Nome')"
                        name="nome"
                        hide-details
                        class="input-style font-size-input text-light-input placeholder-light input-icon"
                        dense
                        flat
                        filled
                        solo
                      >
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" md="2" id="filtrar">
                      <v-btn
                        @click="filtrar()"
                        :loading="loading"
                        id="btn--filtrar"
                        :ripple="false"
                        class="ma-1 font-weight-bold text-none ms-auto btn-primary bg-gradient-primary py-3 px-6 ms-3"
                        height="39"
                      >
                        <v-icon left dark> ni-zoom-split-in </v-icon>
                        {{ $t("Filtrar") }}
                      </v-btn>
                    </v-col>
                  </div>
                </div>
                <v-card-text class="px-0 py-0">
                  <v-data-table
                    :headers="headers"
                    :items="recursos"
                    :show-expand="true"
                    disable-pagination
                    :single-expand="true"
                    :loading="loading"
                    :item-class="isStatusComum"
                    class="table"
                    :page.sync="page"
                    hide-default-footer
                    @page-count="pageCount = $event"
                    @current-items="filteredItems = $event"
                  >
                    <template v-slot:[`item.data`]="{ item }">
                      {{ item.data | dataHoraParaLeituraComSegundo }}
                    </template>
                    <template v-slot:[`item.competencia`]="{ item }">
                      {{ item.competencia | dataParaLeitura }}
                    </template>
                    <template v-slot:[`item.competenciaAjustada`]="{ item }">
                      <span>{{
                        item.competenciaAjustada ? $t("sim") : $t("nao")
                      }}</span>
                    </template>

                    <template v-slot:[`item.actions`]="{ item }">
                      <v-icon
                        v-if="editar"
                        small
                        name="editBtn"
                        icon
                        elevation="0"
                        :ripple="false"
                        v-can="'ROLE_FuncionarioPonto_Update'"
                        @click="confirmaUpdate(item)"
                        class="btn-ls me-0.90 my-1 rounded-sm ma-1"
                        color="#67748e"
                        >ni-ruler-pencil</v-icon
                      >
                      <v-icon
                        v-can="'ROLE_FuncionarioPonto_Delete'"
                        small
                        name="deleteBtn"
                        @click="confirmaDelete(item)"
                        @click.stop="modalConfirmarDelete = true"
                        :loading="loading"
                        icon
                        elevation="0"
                        :ripple="false"
                        class="btn-ls me-0.90 my-1 rounded-sm ma-1"
                        color="#67748e"
                        >ni-fat-remove</v-icon
                      >
                    </template>

                    <template v-slot:expanded-item="{ item, headers }">
                      <td :colspan="headers.length">
                        <conteudo-expansion-ponto
                          :recurso="item"
                          :loading="loading"
                        >
                        </conteudo-expansion-ponto>
                      </td>
                    </template>
                  </v-data-table>
                </v-card-text>
                <v-card-actions class="card-padding">
                  <v-row>
                    <v-col cols="6" lg="6" class="d-flex align-center">
                      <span class="text-body me-3"
                        >{{ $t("Itens_por_pagina") }}:</span
                      >
                      <v-col cols="3" sm="3">
                        <v-select
                          v-model="pageSize"
                          :items="pageSizes"
                          @change="handlePageSizeChange"
                          :disabled="this.isSearching"
                          :item-value="pageSize"
                          hide-details
                          class="input-style font-size-input text-light-input placeholder-light input-icon"
                          dense
                          flat
                          filled
                          solo
                        ></v-select>
                      </v-col>
                    </v-col>
                    <v-col cols="6" class="ml-auto d-flex justify-end">
                      <v-pagination
                        prev-icon="fa fa-angle-left"
                        next-icon="fa fa-angle-right"
                        class="pagination"
                        color="#516a78ee"
                        v-model="page"
                        :length="totalPages"
                        @input="handlePageChange"
                        :disabled="isSearching"
                        circle
                      ></v-pagination>
                    </v-col>
                  </v-row>
                </v-card-actions>
              </v-card>
            </div>
          </template>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import ListagemBase from "@/components/base/Crud/ListagemBase";
import TabelaSelecionaRecurso from "@/components/base/proprios/TabelaSelecionaRecurso";
import DataService from "@/services/ComumDataService";
import FuncionarioPontoDataService from "@/services/pessoal/FuncionarioPontoDataService";
import ConteudoExpansionPonto from "./ConteudoExpansionPonto.vue";

export default {
  name: "ListagemFuncionarioPontos",

  props: {
    //Props para alterações específicas ao caso
    stringCadastro: { type: String, required: false, default: "novo_cadastro" },
    opcoesLegenda: {
      type: Array,
      required: false,
      default() {
        return [
          { text: "Ativo", color: "green" },
          { text: "Inativo", color: "red" },
        ];
      },
    },
    editar: { type: Boolean, required: false, default: true },
    botaoCadastro: { type: Boolean, required: false, default: true },
  },
  components: {
    TabelaSelecionaRecurso,
    ListagemBase,
    ConteudoExpansionPonto,
    FuncionarioPontoDataService,
  },

  data() {
    return {
      //Recursos
      recursos: [],
      errors: [],
      modalPeriodo: true,
      modalConfirmarDelete: false,
      modalInsert: false,
      modalUpdate: false,
      loading: false,
      periodoSelecionado: {},
      periodoJaFoiBuscado: false,
      deletadoAtual: {},
      deletado: false,
      todosFuncionarios: [],
      isSearching: false,
      modalSelecionaArquivo: false,
      registroImp: {},
      listRegistroImp: [],
      tipoImportacao: "",
      statusReader: "",
      headers: [
        {
          text: this.$t("Matricula"),
          value: "funcionario.matricula",
          sortable: "false",
        },
        {
          text: this.$t("Funcionario"),
          value: "funcionario.nomeSocial",
          sortable: "false",
        },
        {
          text: this.$t("PostoTrabalho"),
          value: "funcionario.postoTrabalho.descricao",
          sortable: "false",
        },
        {
          text: this.$t("Escala_de_trabalho"),
          value: "funcionario.funcionarioEscalaTrabalho.descricao",
          sortable: "false",
        },
        { text: this.$t("Data"), value: "data", sortable: "false" },
        {
          text: this.$t("Competencia"),
          value: "competencia",
          sortable: "false",
        },
        {
          text: this.$t("Competencia_ajustada"),
          value: "competenciaAjustada",
          sortable: "false",
        },

        { text: this.$t("Acoes"), value: "actions", sortable: false },
      ],

      recurso: {
        matricula: "",
        data: "",
        justificativa: "",
      },

      recursoEdit: {
        id: "",
        competencia: "",
      },

      //Paginação
      searchApuracaoDia: "",
      search: {
        matricula: "",
        nome: "",
      },
      searchApuracao: "",
      isSearching: false,

      page: 1,
      totalPages: 0,
      pageSize: 8,
      pageSizes: [8, 16, 24],
      orderBy: null,

      direction: null,
      directions: [
        { text: this.$t("Crescente"), value: "ASC" },
        { text: this.$t("Decrescente"), value: "DESC" },
      ],

      statusReader: "",
      line: "",
      contentFile: null,
      registroPontoTravarJustificativa: false,
      splitItemsRegistroPontoJustificativa: [],
    };
  },
  mounted() {
    this.getFuncionarios();
    this.registroPontoVerificarTravaJustificativa();
  },

  watch: {
    search: function () {
      this.handleSearchChange();
    },
    modalInsert: function (val) {
      if (val) {
        this.justificativa = "";
        this.data = "";
      }
    },
    modalUpdate: function (val) {
      if (val) {
        this.competencia = "";
        this.id = "";
      }
    },
  },

  methods: {
    closeModalPeriodo() {
      this.modalPeriodo = false;
    },

    retrieveData() {
      this.loading = true;
      if (!this.periodoSelecionado.id) {
        this.errors = [{ fieldName: "Periodo", message: "Campo_obrigatorio" }];
        return;
      }

      DataService.get("funcionariosPonto/page/period", {
        params: {
          ...{ periodo: this.periodoSelecionado.id },
          ...this.getRequestParams(),
        },
      }).then((response) => {
        this.recursos = response.data.content;
        this.totalPages = response.data.totalPages;
        this.pageSize = response.data.size;
        this.loading = false;
        if (this.modalPeriodo) {
          this.modalPeriodo = false;
        }
      });
    },

    filtrar() {
      if (
        this.search !== null &&
        ((this.search.matricula != null && this.search.matricula.length > 0) ||
          (this.search.nome != null && this.search.nome.length > 0))
      ) {
        this.loading = true;
        this.isSearching = true;
        DataService.get("funcionariosPonto/period/funcionario", {
          params: {
            inicio: this.periodoSelecionado.inicioPeriodo,
            final: this.periodoSelecionado.finalPeriodo,
            matricula: this.search.matricula,
            nome: this.search.nome,
          },
        }).then((response) => {
          this.recursos = response.data;
          this.loading = false;
        });
      } else {
        this.isSearching = false;
        this.retrieveData();
      }
      console.log(" this.isSearching", this.isSearching);
    },

    /* Paginação */
    getRequestParams() {
      let params = {};
      let page = this.page;
      let pageSize = this.pageSize;
      let orderBy = this.orderBy;
      let direction = this.direction;

      console.log("page", page);

      if (page) {
        params["page"] = page - 1;
      }

      if (pageSize) {
        params["linesPerPage"] = pageSize;
      }

      if (orderBy) {
        params["orderBy"] = orderBy;
      }

      if (direction) {
        params["direction"] = direction;
      }
      console.log("params", params);
      return params;
    },

    handleSearchChange() {
      console.log("handleSearchChange", this.search);
      if (this.search !== null && this.search.matricula > 0) {
        this.isSearching = true;
        this.getAll();
      } else {
        this.isSearching = false;
        this.retrieveData();
      }
    },

    handlePageChange(value) {
      this.page = value;
      this.retrieveData();
    },

    handlePageSizeChange(size) {
      this.pageSize = size;
      this.page = 1;
      this.retrieveData();
    },

    handleOrderByChange(value) {
      this.orderBy = value;
      this.page = 1;
      this.retrieveData();
    },

    handleDirectionChange(value) {
      (this.direction = value), this.retrieveData();
    },
    //Modais de confirmação
    confirmaDelete(item) {
      console.log("confirmaDelete", item);
      this.deletadoAtual = item;
      this.modalConfirmarDelete = true;
    },

    confirmaInsert() {
      console.log("confirmaInsert");
      this.modalInsert = true;
    },

    confirmaUpdate(item) {
      this.recursoEdit.id = item.id;
      console.log("confirmaUpdate");
      console.log("id", this.recursoEdit.id);
      this.modalUpdate = true;
    },

    closeModalInsert() {
      this.loading = false;
      this.modalInsert = false;
    },

    closeModalUpdate() {
      this.loading = false;
      this.modalUpdate = false;
    },

    registroPontoVerificarTravaJustificativa() {
      console.log("registroPontoVerificarTravaJustificativa");
      var parametrizacaoExistente =
        this.$store.state.inquilino.parametrizacoes.find(
          (item) => item.tipo == "RESTRINGIRJUSTIFICATIVAREGISTROPONTO"
        );

      if (parametrizacaoExistente) {
        this.registroPontoTravarJustificativa = parametrizacaoExistente.ativo;

        this.splitItemsRegistroPontoJustificativa =
          parametrizacaoExistente.texto
            .split(";")
            .filter((item) => item.trim() !== ""); // Separa a string pelo ';' e remove itens vazios

        console.log(
          "  this.splitItemsRegistroPontoJustificativa",
          this.splitItemsRegistroPontoJustificativa
        );
      }
    },

    insert() {
      console.log("insert");
      this.loading = true;
      DataService.post("funcionariosPonto", this.recurso).then(
        (response) => {
          this.loading = false;
          this.modalInsert = false;

          this.handleSearchChange();
        },
        (error) => {
          this.loading = false;
          this.modalInsert = false;
          //aqui
          this.$swal.fire(
            this.$t("Erro"),
            this.$t("Verifique_periodo_de_ponto_e_funcionario"),
            "error"
          );
        }
      );
    },

    update() {
      console.log("update");
      this.loading = true;
      DataService.put(
        "funcionariosPonto/" + this.recursoEdit.id,
        this.recursoEdit
      ).then(
        (response) => {
          this.loading = false;
          this.modalUpdate = false;
          this.recursoEdit.competencia = "";
          this.recursoEdit.id = "";
          this.handleSearchChange();
        },
        (error) => {
          this.loading = false;
          this.modalUpdate = false;
          this.errors = error.data.errors;
        }
      );
    },

    deleteRecurso() {
      console.log("deleteRecurso", this.deletadoAtual.id);
      FuncionarioPontoDataService.delete(this.deletadoAtual.id)
        .then(() => {
          this.modalConfirmarDelete = false;
          this.deletado = true;
          this.handleSearchChange();
        })
        .catch((error) => {
          this.confirmar = false;
          window.scrollTo({ top: 0, left: 0, behavior: "smooth" });

          this.erros = error.response.data.errors;
        });
    },

    isStatusComum: function (item) {
      return item.statusComum;
    },

    mounted() {
      this.getFuncionarios();
    },

    getFuncionarios() {
      FuncionarioPontoDataService.getFuncionarios()
        .then((response) => {
          console.log(response.data);
          this.todosFuncionarios = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    // invert(item) {
    //   FuncionarioPontoDataService.invert(item.id).then(() => {
    //     console.log("invert sucess");
    //     this.retrieveData();
    //   });
    // },

    // share(item) {
    //   FuncionarioPontoDataService.share(item.id).then(() => {
    //     console.log("share sucess");
    //     this.retrieveData();
    //   });
    // },

    editRecurso(id) {
      console.log(id);
      this.$router.push({
        name: "editar.funcionarioponto",
        params: { id: id },
      });
    },

    importExternal() {
      console.log("importExternal", this.tipoImportacao);
      if (this.tipoImportacao === "AFDT") {
        this.importAFDT();
      } else if (this.tipoImportacao === "AFD") {
        this.importAFD(this.periodoSelecionado);
      } else if (this.tipoImportacao === "CSV") {
        this.importCSV(this.periodoSelecionado);
      } else if (this.tipoImportacao === "ControlID") {
        this.importIdControl();
      } else {
        this.$swal.fire(
          this.$t("Erro"),
          this.$t("Selecione_tipo_arquivo"),
          "error"
        );
      }
    },

    async importIdControl() {
      await this.login();
      await this.importAFD(this.periodoSelecionado, this.contentFile);
    },

    async login() {
      const loginData = {
        login: "admin",
        password: "admin",
      };

      try {
        const xhr = new XMLHttpRequest();
        xhr.open("POST", "https://10.10.3.233/login.fcgi", true);
        xhr.setRequestHeader("Content-Type", "application/json");
        xhr.onreadystatechange = () => {
          if (xhr.readyState === 4) {
            if (xhr.status === 200) {
              const response = JSON.parse(xhr.responseText);
              const session = response.session;
              this.getAfd(session);
            } else {
              console.error("Erro no login:", xhr.statusText);
            }
          }
        };
        xhr.send(JSON.stringify(loginData));
      } catch (error) {
        console.error("Erro no login:", error);
      }
    },

    async getAfd(session) {
      if (!session) {
        console.error("Faça o login primeiro");
        return;
      }

      const afdData = {
        initial_date: {
          day: 1,
          month: 1,
          year: 2023,
        },
      };

      const xhr = new XMLHttpRequest();
      xhr.open(
        "POST",
        `https://10.10.3.233/get_afd.fcgi?session=${session}`,
        true
      );
      xhr.setRequestHeader("Content-Type", "application/json");
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            this.contentFile = xhr.responseText;
            console.log("Dados AFD: \n", this.contentFile);
          } else {
            console.error("Erro ao obter AFD:", xhr.statusText);
          }
        }
      };
      xhr.send(JSON.stringify(afdData));
      console.log("Dados AFD_DATA:", this.afdData);
    },

    readFile() {
      return new Promise((resolve, reject) => {
        this.file = this.$refs.doc.files[0];
        const reader = new FileReader();
        if (
          this.file.name.includes(".csv") ||
          this.file.name.includes(".txt")
        ) {
          reader.onload = (res) => {
            this.modalSelecionaArquivo = false;
            resolve(res.target.result);
          };
          reader.onerror = (err) => console.log(err);
          reader.readAsText(this.file);
        } else {
          reader.onerror = reject;
          reader.onload = (res) => {
            this.modalSelecionaArquivo = false;
            resolve(res.target.result);
          };
          reader.onerror = (err) => console.log(err);
          reader.readAsText(this.file);
        }
      });
    },

    async importCSV() {
      this.loading = true;
      this.contentFile = await this.readFile();
      console.log("this.file", this.file);
      //Importação padrão CSV
      if (this.file.name.includes(".csv")) {
        //Lendo arquivo
        console.log("this.contentFile", this.contentFile);
        //Split
        const lines = this.contentFile.split("\r\n");
        lines.pop();

        //Convertendo para objeto Json
        var jsonObj = [];
        var headers = lines[0].split(";");
        for (var i = 1; i < lines.length; i++) {
          var data = lines[i].split(";");
          var obj = {};
          for (var j = 0; j < data.length; j++) {
            //Tratamentos especificos para formatações

            //Inclui no objeto apenas quando existir valor
            if (data[j].trim().length > 0) {
              //Data
              if (
                data[j].trim().indexOf("/") === 2 &&
                data[j].trim().length === 10
              ) {
                obj[headers[j].trim()] = this.formatedDate(data[j].trim());
              }
              if (
                data[j].trim().indexOf("/") === 2 &&
                data[j].trim().length === 16
              ) {
                console.log("tipo DateTime");
                obj[headers[j].trim()] = this.formatedDateTime(data[j].trim());
              } else if (headers[j].trim().indexOf("valor") !== -1) {
                obj[headers[j].trim()] = data[j]
                  .trim()
                  .replace(".", "")
                  .replace(",", ".");
              } else {
                obj[headers[j].trim()] = data[j].trim();
              }
            }
          }
          jsonObj.push(obj);
        }

        FuncionarioPontoDataService.insertSimplifiedList(jsonObj)
          .then(() => {
            this.retrieveData();
            jsonObj = [];
            this.loading = false;
            this.modalImportArquivo = false;
            this.$swal.fire(
              this.$t("Sucesso"),
              this.$t("Importacao_realizada_sucesso"),
              "success"
            );
          })
          .catch((error) => {
            //Tratando objeto de retorno
            this.loading = false;
            jsonObj = [];
            console.log("error - insertSimplifiedList", error.response.data);
            var linesErr = error.response.data.errors[0].message.split(",");
            var objTratErr = {};
            var listTratErr = [];
            var lineNumber = 0;
            for (var x = 0; x < linesErr.length; x++) {
              objTratErr = {};
              objTratErr.message = linesErr[x]
                .substring(linesErr[x].indexOf(":") + 2)
                .trim();
              lineNumber =
                parseInt(
                  linesErr[x].substring(
                    linesErr[x].indexOf("[") + 1,
                    linesErr[x].indexOf("]")
                  )
                ) + 2;
              objTratErr.fieldName =
                "Linha: " +
                lineNumber.toString() +
                " - " +
                linesErr[x].substring(
                  linesErr[x].indexOf("]") + 2,
                  linesErr[x].indexOf(":")
                );
              objTratErr.line = lineNumber;
              listTratErr.push(objTratErr);
              //console.log("objTratErr", objTratErr);
            }
            listTratErr = listTratErr.sort(this.compareArrayError);
            this.modalImportArquivo = false;
            window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
            //console.log("error.response", error.response);
            this.errors = listTratErr;
          });
      } else {
        this.loading = false;
        this.modalImportArquivo = false;

        this.$swal.fire(this.$t("Erro"), this.$t("Arquivo_invalido"), "error");
      }
    },

    formatedDateTime: function (dataValue) {
      let data = dataValue;
      console.log("data - formatedDateTime", data);
      console.log("data - formatedDateTime - length", data.length);
      if (data.length == 16) {
        console.log(data.toString());
        var dia = data.split("/")[0];
        var mes = data.split("/")[1];
        var ano = data.split("/")[2].split(" ")[0];

        var horamin = data.split(" ")[1];
        var horas = horamin.split(":")[0];
        var minutos = horamin.split(":")[1];

        let string =
          ano + "-" + mes + "-" + dia + "T" + horas + ":" + minutos + ":00";
        console.log("formated", string);
        return string;
      } else {
        return data;
      }
    },
    async importAFDT() {
      this.loading = true;

      console.log("ImportAFDT");

      this.contentFile = await this.readFile(this.file);
      this.loading = true;

      var lines = this.contentFile.split("\n");
      this.listRegistroImp = [];
      for (var line = 0; line < lines.length; line++) {
        this.line = lines[line];
        //console.log("Tipo linha", this.line.substr(0, 1));
        //console.log("linha", this.line);
        //Importação DEXION
        if (
          this.line.substr(9, 1) === "2" &&
          (this.line.substr(51, 1) === "E" || this.line.substr(51, 1) === "S")
        ) {
          //Limpando dados
          this.registroImp = {
            data: "",
            justificativa: "",
            origem: "",
            pis: "",
          };

          this.registroImp.pis = this.line.substr(23, 11);
          this.registroImp.data =
            this.line.substr(14, 4) +
            "-" +
            this.line.substr(12, 2) +
            "-" +
            this.line.substr(10, 2) +
            "T" +
            this.line.substr(18, 2) +
            ":" +
            this.line.substr(20, 2) +
            ":00";
          this.registroImp.origem = this.line.substr(34, 17);
          if (this.line.substr(54, 1) === "I") {
            this.registroImp.justificativa = this.line.substr(55, 100).trim();
          } else {
            this.registroImp.justificativa = null;
          }

          this.listRegistroImp.push(this.registroImp);
        }
      }

      console.log("Registros Imp: ");
      console.log(this.listRegistroImp);
      await DataService.post(
        "funcionariosPonto/import/rep",
        this.listRegistroImp
      )
        .then((res) => {
          console.log("DataService.post(funcionariosPonto/import/rep");
        })
        .catch((error) => {
          // this.loading = false;
          // jsonObj = [];
          // console.log("error - insertSimplifiedList", error.response);
          // this.$swal.fire(
          //   this.$t("Erro"),
          //   this.$t("Favor_tente_novamente_mais_tarde"),
          //   "error"
          // );
        });

      this.modalSelecionaArquivo = false;
      this.loading = false;
      this.$swal.fire(
        this.$t("Sucesso"),
        this.$t("Importacao_realizada"),
        "success"
      );
    },

    async importAFD(periodo, contentFile) {
      console.log("importAFD");
      // var file = this.$refs.doc.files[0];
      // var reader = new FileReader();
      var origem = "";
      var inicioPeriodo = new Date(periodo.inicioPeriodo);
      var finalPeriodo = new Date(periodo.finalPeriodo);
      //Acrescentando dias no period
      inicioPeriodo.setDate(inicioPeriodo.getDate());
      finalPeriodo.setDate(finalPeriodo.getDate());

      if (contentFile !== undefined && contentFile !== null) {
        // Verificar se contentFile não é undefined e não é null
        this.contentFile = contentFile;
        var lines = this.contentFile.split(/\r\n|\r|\n/);
        var lines = lines.filter((line) => line.trim() !== "");
      } else {
        // if (this.file) {
        this.contentFile = await this.readFile(this.file);
        this.loading = true;
        var lines = this.contentFile.split("\n");
        console.log("lines", lines);
        // } else {
        //   console.error("Nenhum arquivo ou conteúdo disponível.");
        //   return;
        // }
        // this.contentFile = this.contentFile.replace(/\r\n/g, '\n');
        // const lineBreaks = detect(this.contentFile);
        // var lines = [];
        // var startPos = 0;

        // for (const lineBreak of lineBreaks) {
        //   lines.push(this.contentFile.slice(startPos, lineBreak.position));
        //   startPos = lineBreak.position + lineBreak.bytes;
        // }
      }
      this.loading = true;

      this.listRegistroImp = [];
      for (var line = 0; line < lines.length; line++) {
        this.line = lines[line];
        if (this.line.substr(9, 1) === "1") {
          origem = this.line.substr(187, 17);
        } else if (this.line.substr(9, 1) === "3") {
          console.log("contentFile: ", this.line);
          //Limpando dados
          this.registroImp = {
            data: "",
            justificativa: "",
            origem: "",
            pis: "",
          };
          this.registroImp.pis = this.line.substr(23, 11);
          this.registroImp.data =
            this.line.substr(14, 4) +
            "-" +
            this.line.substr(12, 2) +
            "-" +
            this.line.substr(10, 2) +
            "T" +
            this.line.substr(18, 2) +
            ":" +
            this.line.substr(20, 2) +
            ":00";
          this.registroImp.origem = origem;

          this.registroImp.justificativa =
            "NSR:" + this.line.substr(0, 9).trim();

          if (new Date(this.registroImp.data) >= inicioPeriodo) {
            this.listRegistroImp.push(this.registroImp);
            console.log("Data compativel", this.registroImp);
          }
        } else if (this.line.substr(0, 9) === "999999999") {
          console.log("Registros Imp: ");
          console.log(this.listRegistroImp);
          await DataService.post(
            "funcionariosPonto/import/rep",
            this.listRegistroImp
          )
            .then((res) => {
              //this.requestResources();
              //this.loading = false;
            })
            .catch((error) => {});
        }
      }

      this.modalSelecionaArquivo = false;
      this.loading = false;
      this.$swal.fire(
        this.$t("Sucesso"),
        this.$t("Importacao_realizada"),
        "success"
      );
    },

    showimportExternal() {
      this.modalSelecionaArquivo = true;
    },

    exportarAfd() {
      this.loading = true;
      DataService.put("funcionariosPonto/exportarAfd", this.periodoSelecionado)
        .then((res) => {
          console.log("res", res, res.data);
          this.loading = false;
          this.download(
            res.data,
            "AFD_" +
              this.periodoSelecionado.inicioPeriodo +
              "_" +
              this.periodoSelecionado.finalPeriodo +
              ".txt",
            "application/txt"
          );

          this.$swal.fire(
            this.$t("Sucesso"),
            this.$t("Documento_gerado_com_sucesso"),
            "success"
          );
        })
        .catch((error) => {
          this.loading = false;
          this.erros = error.response.data.errors;
          // console.log(" this.erros", error.response);
          // this.$swal.fire(
          //   this.$t("Erro"),
          //   this.$t("Falha_ao_exportar"),
          //   "error"
          // );
        });
    },

    download(content, fileName, mimeType) {
      var a = document.createElement("a");
      mimeType = mimeType || "application/octet-stream";

      if (navigator.msSaveBlob) {
        // IE10
        navigator.msSaveBlob(
          new Blob([content], {
            type: mimeType,
          }),
          fileName
        );
      } else if (URL && "download" in a) {
        //html5 A[download]
        a.href = URL.createObjectURL(
          new Blob([content], {
            type: mimeType,
          })
        );
        a.setAttribute("download", fileName);
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      } else {
        location.href =
          "data:application/octet-stream," + encodeURIComponent(content); // only this mime type is supported
      }
    },
    utils_todayPlusDays(days) {
      let now = new Date();
      now.setDate(now.getDate() + days);
      return now;
    },
  },
};
</script>

<style>
.doubt-btn {
  position: fixed;
  right: 10px;
  bottom: 10px;
}

#app .INATIVO {
  color: red;
}

#app .ATIVO {
  color: #67748e;
}

.rounded-div {
  background-color: red;
  border-radius: 999px;
}
</style>

