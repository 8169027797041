
//import {startTutorial} from "../components/aaTutorialPackage/main.js"


export const checkPendingTutorialModal = {
  //Pra funcionar tem que adicionar o evento no campo cep e declarar dataservice como uma
  //propriedade de data no componente

  mounted() {

  },
  data() {
    return {
    };
  },
  computed: {
    pendingTutorial() {
      return this.$store.state.pendingTutorial
    }
  },
  methods: {
    /**
     * Pula essa parte do tutorial porque ela foi completada
     * 
     */
    checkPendingTutorialSucess() {
      console.log('Checking pending tutorial success')
      if (this.pendingTutorial && this.pendingTutorial?.routeName == this.$route.name) {
        console.log('Checking pending tutorial success inside')
        startTutorial(this.pendingTutorial.steps)
        this.$store.dispatch('SET_PENDING_TUTORIAL', this.pendingTutorial.next);
      }
    },

    /**
     * Da a opcao do usuario pular essa parte do tutorial
     * 
     */
    checkPendingTutorialError() {
      if (this.$store.state.pendingTutorial?.name && this.$route.name == this.$store.state.pendingTutorial?.routeName) {
        this.$store.dispatch('SET_SKIP_OPTION_MODAL', true);
      }
    }
  }
};
