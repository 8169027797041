var render = function render(){var _vm=this,_c=_vm._self._c;return _c('CadastroBase',{attrs:{"nomePlural":"FuncionarioAvisoPrevios","nomeSingular":"FuncionarioAvisoPrevio","submitPersonalizado":true,"submited":_vm.submited},on:{"submitSuccessInsert":_vm.emitSubmitSuccess,"submitErrorInsert":_vm.emitSubmitError,"closeModalInsert":_vm.closeModal}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('TabelaSelecionaRecurso',{attrs:{"url":"funcionarios/active","titulo":"Funcionario","headers":[
            {
              text: this.$t('Matricula'),
              value: 'matricula',
              sortable: 'false',
            },
            {
              text: this.$t('Nome'),
              value: 'pessoa.nome',
              sortable: 'false',
            },
            {
              text: this.$t('Sindicato'),
              value: 'sindicato.descricao',
              sortable: 'false',
            },
            {
              text: this.$t('Cargo'),
              value: 'cargo.descricao',
              sortable: 'false',
            },
            {
              text: this.$t('PostoTrabalho'),
              value: 'postoTrabalho.descricao',
              sortable: 'true',
            },
          ]},on:{"recurso":function($event){_vm.recurso.funcionario = $event}}})],1),_c('v-col',{attrs:{"cols":"12","md":"4","id":"campo-dt-inicio"}},[_c('CampoData',{attrs:{"label":"Inicio_do_periodo"},on:{"date":function($event){_vm.recurso.inicio = $event}}})],1),_c('v-col',{attrs:{"cols":"12","md":"4","id":"campo-dt-encerramento"}},[_c('CampoData',{attrs:{"label":"Final_do_periodo"},on:{"date":function($event){_vm.recurso.encerramento = $event}}})],1),_c('v-col',{staticClass:"d-flex",attrs:{"cols":"12","md":"4"}},[_c('v-select',{staticClass:"input-style font-size-input text-light-input placeholder-light input-icon",attrs:{"id":"origemAvisoPrevio","items":_vm.tiposOrigemAvisoPrevio,"label":_vm.$t('origemAvisoPrevio'),"dense":"","flat":"","filled":"","solo":""},model:{value:(_vm.recurso.origemAvisoPrevio),callback:function ($$v) {_vm.$set(_vm.recurso, "origemAvisoPrevio", $$v)},expression:"recurso.origemAvisoPrevio"}})],1),_c('v-col',{staticClass:"d-flex",attrs:{"cols":"12","md":"4"}},[_c('v-select',{staticClass:"input-style font-size-input text-light-input placeholder-light input-icon",attrs:{"id":"tipoAvisoPrevio","items":_vm.tiposAvisoPrevio,"label":_vm.$t('tipoAvisoPrevio'),"dense":"","flat":"","filled":"","solo":""},model:{value:(_vm.recurso.tipoAvisoPrevio),callback:function ($$v) {_vm.$set(_vm.recurso, "tipoAvisoPrevio", $$v)},expression:"recurso.tipoAvisoPrevio"}})],1),(
          _vm.recurso.origemAvisoPrevio === 'EMPRESA' &&
          _vm.recurso.tipoAvisoPrevio === 'TRABALHADO'
        )?_c('v-col',{staticClass:"d-flex",attrs:{"cols":"12","md":"4"}},[_c('v-select',{staticClass:"input-style font-size-input text-light-input placeholder-light input-icon",attrs:{"id":"beneficioAvisoPrevio","items":_vm.tiposBeneficioAvisoPrevio,"label":_vm.$t('beneficioAvisoPrevio'),"dense":"","flat":"","filled":"","solo":""},model:{value:(_vm.recurso.beneficioAvisoPrevio),callback:function ($$v) {_vm.$set(_vm.recurso, "beneficioAvisoPrevio", $$v)},expression:"recurso.beneficioAvisoPrevio"}})],1):_vm._e(),_c('v-col',{attrs:{"cols":"12","md":"4","id":"dataHomologacao"}},[_c('CampoData',{attrs:{"label":"dataHomologacao"},on:{"date":function($event){_vm.recurso.dataHomologacao = $event}}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('div',[_c('v-text-field',{staticClass:"input-style font-size-input text-light-input placeholder-light input-icon",attrs:{"label":_vm.$t('Observacao'),"name":"Observacao","dense":"","flat":"","filled":"","solo":""},model:{value:(_vm.recurso.observacao),callback:function ($$v) {_vm.$set(_vm.recurso, "observacao", $$v)},expression:"recurso.observacao"}})],1)])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }